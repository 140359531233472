import { Link, useParams } from "react-router-dom";
import { getChannelDetails } from "../../api/channels";
import { useQuery } from "@tanstack/react-query";
import { AlertCircle, ChevronLeft } from "lucide-react";
import Button, { IconButton } from "../../Components/Button";
import IntroCard from "./Sections/Cards/IntroCard";
import ChartBox from "./Sections/Analytics/ChartBox";
import ChannelDetails from "./Sections/Analytics/ChannelDetails";

export default function ChannelPage() {
    const { id } = useParams();

    const getChannel = async () => {
        let resp = await getChannelDetails(id);
        console.log('got here', resp);
        if (!resp.success) {
            console.log(resp.message);
            throw new Error(resp.message);
        }
        let data = resp.data;
        return data;
    }

    const { isError, error } = useQuery({
        queryKey: ["channel", id],
        queryFn: getChannel,
        retry: false,
        staleTime: 3600 * 1000
    });

    return (
        <div
            className="flex flex-col p-4"
        >
            <div
                className="flex flex-row items-center justify-start mb-4"
            >
                <Link
                    to="/channels"
                >
                    <IconButton>
                        <ChevronLeft />
                    </IconButton>
                </Link>
                <div
                    className="text-xl font-semibold text-slate-600"
                >
                    Channel Details
                </div>
            </div>
            {!isError &&
                <div
                    className="flex gap-4 flex-wrap"
                >
                    {/* <IntroCard /> */}
                    <ChannelDetails />
                    {
                        // window.innerWidth > 768 &&
                        <>
                            <div className="flex flex-row items-center w-full p-4 rounded-md mt-4">
                                <hr className="w-full border-gray-200 mx-3" />
                                <span
                                    className="text-lg text-slate-600"
                                >
                                    Analytics
                                </span>
                                <hr className="w-full border-gray-200 mx-3" />
                            </div>
                            <ChartBox />
                        </>
                    }
                    {/* <div className="flex flex-row items-center w-full p-4 rounded-md mt-4">
                        <hr className="w-full border-gray-200 mx-3" />
                        <span
                            className="text-lg text-slate-600"
                        >
                            Videos
                        </span>
                        <hr className="w-full border-gray-200 mx-3" />
                    </div> */}
                </div>
            }
            {isError &&
                <div className=" flex flex-col items-center justify-center h-[100dvh]">
                    <AlertCircle className="w-24 h-24 text-red-500" />
                    <div className="text-2xl font-bold text-red-500">{error.message}</div>
                    <div>
                        <Link to="/channels">
                            <Button>
                                Back
                            </Button>
                        </Link>
                    </div>
                </div>
            }
        </div>
    );
}