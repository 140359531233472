import { Lock, User } from "lucide-react";
import { useState } from "react";
import Button from "../../Components/Button";
import { login } from "../../api/auth";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        if (username === '') {
            setError('Email is required');
            return;
        }
        if (password === '') {
            setError('Password is required');
            return;
        }
        setLoading(true);
        login(username, password).then((res) => {
            setLoading(false);
            if (res === true) {
                return;
            }
            if (res !== false) {
                setError(res);
                return;
            }
        })
    }

    return (
        <div
            className="flex flex-col justify-center sm:items-center p-3 h-screen w-screen text-gray-700"
        >
            <div>
                <h1
                    className="text-4xl font-bold mb-4"
                >
                    Login
                </h1>
                <form
                    onSubmit={handleSubmit}
                    className="sm:w-72 w-full"
                >
                    <div
                        className="flex flex-row border border-gray-300 rounded-lg p-1 mb-2 w-full"
                    >
                        <div
                            className="p-2 rounded-md bg-gray-100 mr-2"
                        >
                            <User
                                size={18}
                            />
                        </div>
                        <input
                            type="text"
                            placeholder="Username"
                            className="outline-none w-full bg-transparent"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div
                        className="flex flex-row border border-gray-300 rounded-lg p-1 mb-1 w-full"
                    >
                        <div
                            className="p-2 rounded-md bg-gray-100 mr-2"
                        >
                            <Lock
                                size={18}
                            />
                        </div>
                        <input
                            type="password"
                            placeholder="Password"
                            className="outline-none w-full bg-transparent"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <p
                        className="text-red-500 text-xs w-full text-start"
                    >
                        {error}
                    </p>
                    <div
                        className="my-4"
                    />
                    <Button
                        type="submit"
                        isLoading={loading}
                        className={`w-full`}
                    >
                        {loading ? 'Loading...' : 'Login'}
                    </Button>
                </form>
            </div>
        </div>
    )
}

export default LoginPage