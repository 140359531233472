import { BASE_URL } from "./consts";
import { toast } from "react-toastify";
import axios from "axios";
import { store } from "../state/reducer";
import { login as addLogin } from "../state/auth/slice";

export const login = async (username, password) => {
    const resp = await axios.post(`${BASE_URL}/api/login/`, {
        username,
        password,
    }).catch((err) => {
        // toast.error(err.response.data.message);
        return err.response;
    });
    if (resp.status === 200) {
        store.dispatch(addLogin({ token: resp.data.token }));
        toast.success(resp.data.message);
        return true;
    }
    console.log(resp);
    toast.error(resp.data.message);
    return resp.data.message;
}