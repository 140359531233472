import { useEffect, useState } from "react";
import Button from "../../../Components/Button";
import Drawer from "../../../Components/Drawer";
import { deleteResearch, getResearches } from "../../../api/research";
import { useInfiniteQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import { LoadingTableDataCell, Table, TableBody, TableDataCell, TableDropdownAction, TableHeader, TableHeaderCell, TableOptionCell, TableRow } from "../../../Components/Table/table";
import { Loader2 } from "lucide-react";


const columns = [
    'Title',
    'Status',
    'Keywords',
    'Min/Max Subscribers',
    'Min Views',
    'Results',
]

const Research = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dropdown, setDropdown] = useState('');
    const [records, setRecords] = useState([]);

    const fetchData = async ({
        pageParam = 1,
    }) => {
        let res = (await getResearches({ page: pageParam })).data;
        return {
            data: res.results,
            nextPage: res.next ? pageParam + 1 : null,
        };
    }

    const { data, isLoading, refetch, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [{ k: 'researches' }],
        queryFn: fetchData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    useEffect(() => {
        if (data) {
            let records = [];
            data.pages.forEach((page) => {
                records = [...records, ...page.data];
            });
            setRecords(records);
        }
    }, [data]);

    return (
        <>
            <div className="h-full w-full px-4">
                <div className="flex flex-row items-center justify-between mt-8">
                    <div
                        className=" text-2xl text-slate-700 text-start"
                    >
                        Competitor Researches
                    </div>
                    <div className=" w-24">
                        <Button
                            onClick={() => setDrawerOpen(true)}
                        >
                            New
                        </Button>
                    </div>
                </div>
                <Table>
                    <TableHeader>
                        {columns.map((column) => (
                            <TableHeaderCell>
                                {column}
                            </TableHeaderCell>
                        ))}
                        {/* <th scope="col" className="relative px-4 py-3">
                                <span className="sr-only">Action</span>
                            </th> */}
                        <TableHeaderCell>
                            <span className="sr-only">Action</span>
                        </TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {(records?.length ?? 0) === 0 && (
                            isLoading ?
                                <TableRow>
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                </TableRow> :
                                <tr>
                                    <td
                                        colSpan={columns.length + 1}
                                        className="text-center py-4"
                                    >
                                        <div className="flex flex-col items-center">
                                            <span className="text-slate-500">
                                                No Researches Found
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                        )}

                        {(records ?? []).map((row) => (
                            <TableRow className="text-start">
                                <NavLink
                                    to={`/research-channels/${row.id}`}
                                    className="flex flex-row items-center justify-start"
                                >
                                    <TableDataCell
                                    >
                                        {row.name}
                                    </TableDataCell>
                                </NavLink>
                                <TableDataCell>
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${row.done ? "bg-green-100 text-green-800" : "bg-purple-100 text-purple-800"}`}>
                                        {row.done ? 'Done' : 'Pending'}
                                    </span>
                                </TableDataCell>
                                <TableDataCell className={"text-sm text-slate-500 "}>
                                    {row.keywords.split('\n').length}
                                </TableDataCell>
                                <TableDataCell className={"text-sm text-slate-500 "}>
                                    {row.minSubs.toLocaleString()} - {row.maxSubs.toLocaleString()}
                                </TableDataCell>
                                <TableDataCell className={"text-sm text-slate-500 "}>
                                    {row.minViews.toLocaleString()}
                                </TableDataCell>
                                <TableDataCell className={"text-sm text-slate-500 "}>
                                    {row.results.toLocaleString()}
                                </TableDataCell>
                                <TableOptionCell
                                    dropdown={dropdown}
                                    setDropdown={setDropdown}
                                    key={row.id}
                                    keyId={row.id}
                                    className={'right-20'}
                                >
                                    <NavLink
                                        to={`/research-channels/${row.id}`}
                                        className="text-slate-600"
                                    >
                                        <TableDropdownAction>
                                            View Channels
                                        </TableDropdownAction>
                                    </NavLink>
                                    <TableDropdownAction
                                        onClick={async () => {
                                            setDropdown('');
                                            await deleteResearch(row.id);
                                            refetch();
                                        }}
                                    >
                                        <span className="text-red-600">
                                            Delete
                                        </span>
                                    </TableDropdownAction>
                                </TableOptionCell>
                            </TableRow>
                        ))}
                        {hasNextPage &&
                            <TableRow>
                                <TableDataCell
                                    colSpan={8}
                                    className="text-center pt-4 pb-6"
                                >
                                    {isFetchingNextPage
                                        ?
                                        <Loader2
                                            className=' animate-spin m-auto'
                                        />
                                        :
                                        <div
                                            className='w-24 m-auto'
                                        >
                                            <Button
                                                onClick={fetchNextPage}
                                            >
                                                More
                                            </Button>
                                        </div>
                                    }
                                </TableDataCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
            <Drawer isOpen={drawerOpen} setDrawerOpen={setDrawerOpen} reload={refetch} />
        </>
    )
}

export default Research;