import { useSearchParams } from "react-router-dom";

export default function FilterChips() {
    const [searchParams, setSearchParams] = useSearchParams();
    return (<>
        <div className={`flex flex-wrap`}>
            {searchParams.entries().map((param, index) => {
                // {searchParams.toString().split('&').map((param, index) => {
                const [key, value] = param;
                if (key === '') return null;
                return (<div key={index} className="flex flex-row items-center justify-center bg-slate-100 rounded-full px-4 py-2 mx-2 my-2">
                    <span className="text-slate-700 text-sm font-semibold">{key}={value}</span>
                    <button onClick={() => {
                        searchParams.delete(key);
                        setSearchParams(searchParams);
                    }} className="ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-slate-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>);
            })}
        </div>
    </>);
}