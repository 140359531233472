
import React from "react";

const SideMenuItem = ({ active, icon, isOpen, ...props }) => {
    return (
        active
            ? (
                <div className="py-2 px-4 mx-2 text-start cursor-pointer rounded-md flex flex-row items-center text-red-400 font-semibold transition-all duration-300 whitespace-nowrap" {...props}>
                    <div className="absolute h-3 w-1 rounded-lg bg-red-600 transition-all duration-300">
                        <div className="h-3 w-1 bg-red-400 blur-sm translate-x-[1px] transition-all duration-300"></div>
                    </div>
                    {/* <div className="ml-4">
                        {icon}
                    </div> */}
                    <div className="ml-3 duration-200">
                        {props.children}
                    </div>
                </div>
            )
            : (
                <div className="group py-2 px-4 mx-2 text-start cursor-pointer rounded-md flex flex-row items-center transition-all duration-300 text-slate-600 whitespace-nowrap" {...props}>
                    <div className="absolute h-3 w-1 rounded-lg bg-red-600 opacity-0 -translate-x-4 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300">
                        <div className="h-3 w-1 bg-red-400 blur-sm translate-x-[1px]"></div>
                    </div>
                    {/* <div className="ml-4">
                        {icon}
                    </div> */}
                    <div
                        className="group-hover:text-red-500 ml-4 duration-200"
                    >
                        {props.children}
                    </div>
                </div>
            )
    );
};

export default SideMenuItem;