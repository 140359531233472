import React, { useEffect } from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AreaChart as LineChart, Area as Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useContainerDimensions } from './fetchWidth';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getChannelHistory } from '../../../../api/channels';


const ChartBox = () => {

    const { id } = useParams();
    const [chartType, setChartType] = React.useState('viewsNew');

    const [values, setValues] = React.useState([]);

    const containerRef = React.useRef(null);
    const { height, width } = useContainerDimensions(containerRef);

    const keys = [
        { name: 'Views', value: 'views' },
        { name: 'Subscribers', value: 'subscribers' },
        { name: 'Videos', value: 'videos' },
        { name: 'New Views', value: 'viewsNew' },
        { name: 'New Subscribers', value: 'subNew' },
        { name: 'New Videos', value: 'videosNew' }
    ]

    const getChannel = async () => {
        let resp = await getChannelHistory(id);
        console.log('got here', resp);
        if (!resp.success) {
            console.log(resp.message);
            throw new Error(resp.message);
        }
        let data = resp.data;
        return data;
    }

    const { data, isLoading } = useQuery({
        queryKey: ["channel-history", id],
        queryFn: getChannel,
        retry: false,
        staleTime: 3600 * 1000
    });

    useEffect(() => {
        if (data) {
            let arr = [];
            for (let i = 0; i < data.length; i++) {
                let obj = {
                    name: data[i].date,
                    value: data[i][chartType]
                }
                arr.push(obj);
            }
            setValues(arr);
        }
    }, [data, chartType])

    return (
        <div
            className="w-full p-4 rounded-md"
        >
            <div
                className="w-full h-80 mr-5 -ml-2"
                ref={containerRef}
            >
                {!isLoading && data?.length > 4 &&
                    <>
                        <div
                            className="flex flex-row justify-start gap-4 items-center mb-4 mx-4 max-w-full overflow-x-auto"
                        >
                            {
                                keys.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`font-medium border-b-2 ${chartType === item['value'] ? 'text-red-600 border-b-red-500 drop-shadow-[0_0_1px_rgba(255,0,0,0.35)]' : 'text-slate-700 border-b-transparent drop-shadow-none'} cursor-pointer duration-200 hover:border-b-red-500`}
                                            onClick={() => {
                                                setChartType(item['value']);
                                            }}
                                        >
                                            {item['name']}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <ResponsiveContainer>
                            <LineChart
                                ref={containerRef}
                                height={height - 30}
                                width={width}
                                data={values}
                                className='w-80 h-80'
                                key={chartType}
                            >
                                <defs>
                                    {
                                        [{ color: '#8884d8', id: 'colorUv' }, { color: '#82ca9d', id: 'colorPv' }].map((item, index) => {
                                            return (
                                                <linearGradient key={index} id={item['id']} x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={item['color']} stopOpacity={0.5} />
                                                    <stop offset="85%" stopColor={item['color']} stopOpacity={0} />
                                                </linearGradient>
                                            )
                                        })
                                    }
                                </defs>
                                <CartesianGrid
                                    vertical={false}
                                    height={0.5}
                                />
                                <XAxis
                                    dataKey="name"
                                    axisLine={false}
                                    tick={{
                                        fontSize: 14
                                    }}
                                />
                                <YAxis
                                    type="number"
                                    interval={0}
                                    axisLine={false}
                                    tickCount={7}
                                    tick={{ fontSize: 14 }}
                                    tickFormatter={(value) => {
                                        let suffix = '';
                                        if (value >= 1000000) {
                                            value /= 1000000;
                                            suffix = 'm';
                                        }
                                        else if (value >= 1000) {
                                            value /= 1000;
                                            suffix = 'k';
                                        }
                                        return value + suffix;
                                    }}
                                />
                                <Tooltip
                                    content={({ payload }) => {
                                        const name = payload[0]?.payload?.name;
                                        const value = payload[0]?.payload?.value;
                                        if (payload.length > 0) {
                                            return (
                                                <div
                                                    className="bg-white bg-opacity-70 p-2 rounded-md text-start border border-slate-300"
                                                >
                                                    <div
                                                        className="text-slate-500"
                                                    >
                                                        Date: {name}
                                                    </div>
                                                    <span
                                                        className="text-slate-500"
                                                    >
                                                        <b>{value.toLocaleString()}</b> {chartType === 'views' || chartType === 'viewsNew' ? 'Views' : chartType === 'subscribers' || chartType === 'subNew' ? 'Subscribers' : 'Videos'}
                                                    </span>
                                                    &nbsp;
                                                </div>
                                            )
                                        }
                                    }}
                                />
                                <Line
                                    // animationDuration={500}
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeMiterlimit={5}
                                    strokeLinejoin='round'
                                    type="monotone"
                                    dataKey='value'
                                    name='Views'
                                    stroke='#8884d8'
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </>}
                {
                    isLoading && <div className="flex flex-col items-center justify-center">
                        <div
                            className='m-20 w-20 h-20 bg-slate-200 rounded-full animate-ping'
                        />
                    </div>
                }
                {
                    !isLoading && data?.length <= 4 && <div className="flex flex-col h-48 items-center justify-center">
                        <div className="text-2xl text-red-500">Not enough data to show chart</div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChartBox;
