import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTopVideos } from '../../../api/videos';
import { AlertTriangle, Loader2 } from 'lucide-react';
import VideoCard from '../../../Components/VideoCard';

export default function TopVideosSection() {
    const fetchData = async () => {
        const res = await getTopVideos();
        let results = res.data.results;
        return results;
    }

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['top-videos'],
        queryFn: fetchData,
        staleTime: 360000,
    });

    return (<>
        <div
            className="flex flex-col w-full"
        >
            <div className='flex flex-row items-center justify-between mb-5'>
                <div className='text-xl text-slate-700 font-medium'>
                    Top Videos
                </div>
                <hr className='flex-1 mx-8 bg-slate-300' />
            </div>
            <div className={`text-slate-500 mx-auto my-8 ${isLoading ? '' : 'hidden'}`}>
                <Loader2
                    className='animate-spin'
                />
            </div>
            <div className={`text-slate-500 mx-auto my-8 ${isError ? '' : 'hidden'}`}>
                {isError && error.message}
            </div>
            <div className='flex flex-col gap-2'>
                {data?.map((video, index) => {
                    return <VideoCard
                        key={index}
                        data={video}
                    />
                })}
            </div>
            {data?.length === 0 && !isLoading && !isError && <div className='flex flex-row text-slate-500 mx-auto my-8'>
                <AlertTriangle
                    className='mr-2'
                />
                No videos found.
            </div>}
        </div>
    </>);
}