import React from 'react';
import Button from './Button';
import { newResearch } from '../api/research';
import { toast } from 'react-toastify';

function Drawer({ isOpen, setDrawerOpen, reload }) {

    const [title, setTitle] = React.useState('');
    const [minSubscribers, setMinSubscribers] = React.useState('');
    const [maxSubscribers, setMaxSubscribers] = React.useState('');
    const [minViews, setMinViews] = React.useState('');
    const [keywords, setKeywords] = React.useState('');
    const [error, setError] = React.useState('');

    const createNewResearch = async () => {
        let data = {
            name: title,
            keywords,
            minSubs: parseInt(minSubscribers),
            maxSubs: parseInt(maxSubscribers),
            minViews: parseInt(minViews),
        };
        let resp = newResearch(data);
        if (!resp.success && resp.message) {
            setError(resp.message);
            return;
        }
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
            reload();
        });
        new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
            reload();
        });
        setDrawerOpen(false);
        toast.success('Research Created Successfully');
    }

    React.useEffect(() => {
        setTitle('');
        // setMinSubscribers('');
        // setMaxSubscribers('');
        // setMinViews('');
        // setKeywords('');
        const escListener = (e) => {
            if (e.key === 'Escape') {
                setDrawerOpen(false);
            }
        };
        document.addEventListener('keydown', escListener);
        return () => {
            document.removeEventListener('keydown', escListener);
        };
    }, [setDrawerOpen]);
    return (
        <>
            <div
                className={`fixed z-40 duration-300 ease-in-out inset-0 bg-gray-800 ${isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setDrawerOpen(false)}
            >
            </div>
            <div className={`fixed z-50 inset-y-0 right-0 bg-gray-50 w-full md:w-2/3 lg:w-1/3 xl:1/3 p-6 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-200 ease-in-out`}>
                <span
                    className='text-2xl font-medium text-gray-800'
                >
                    New Research
                </span>
                <form
                    className='flex flex-col max-w-[500px] m-auto'
                    onSubmit={(e) => {
                        e.preventDefault();
                        createNewResearch();
                    }}
                >
                    <label
                        className='block mt-4 text-start mb-1'
                    >
                        <span clas6sName='text-gray-700'>Title</span>
                    </label>
                    <input
                        className='p-2 rounded-md bg-gray-200 mr-2 outline-none w-full'
                        type='text'
                        placeholder='Title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                    <label
                        className='block mt-4 text-start mb-1'
                    >
                        <span className='text-gray-700'>Minimum Subscribers</span>
                    </label>
                    <input
                        className='p-2 rounded-md bg-gray-200 mr-2 outline-none w-full'
                        type='number'
                        placeholder='3000'
                        value={minSubscribers}
                        onChange={(e) => setMinSubscribers(e.target.value)}
                        required
                    />
                    <label
                        className='block mt-4 text-start mb-1'
                    >
                        <span className='text-gray-700'>Maximum Subscribers</span>
                    </label>
                    <input
                        className='p-2 rounded-md bg-gray-200 mr-2 outline-none w-full'
                        type='number'
                        placeholder='10000'
                        value={maxSubscribers}
                        onChange={(e) => setMaxSubscribers(e.target.value)}
                        required
                    />
                    <label
                        className='block mt-4 text-start mb-1'
                    >
                        <span className='text-gray-700'>Minimum Views(Across Channel)</span>
                    </label>
                    <input
                        className='p-2 rounded-md bg-gray-200 mr-2 outline-none w-full'
                        type='number'
                        placeholder='300000'
                        value={minViews}
                        onChange={(e) => setMinViews(e.target.value)}
                        required
                    />
                    <label
                        className='block mt-4 text-start mb-1'
                    >
                        <span className='text-gray-700'>Keywords</span>
                    </label>
                    <textarea
                        className='p-2 rounded-md bg-gray-200 mr-2 outline-none w-full'
                        type='text'
                        placeholder={'Keywords\nSeparated\nBy\nNew\nLine'}
                        rows={5}
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        required
                    />
                    {error &&
                        <span
                            className='text-red-500 text-start mt-2'
                        >
                            {error}
                        </span>
                    }
                    <div
                        className='flex flex-row justify-between mt-8 gap-8'
                    >
                        <Button>
                            Create
                        </Button>
                        <Button
                            isPrimary={false}
                            onClick={() => setDrawerOpen(false)}
                            type='button'
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>

        </>
    );
}

export default Drawer;
