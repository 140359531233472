// export const BASE_URL = 'http://localhost:8000'; // API URL
// export const BASE_URL = 'https://yt-champ.api.noicetools.com'; // API URL
if (process.env.NODE_ENV === 'development') {
    module.exports = {
        BASE_URL: 'http://localhost:8000',
        // BASE_URL: 'http://192.168.2.172:8000',
    }
} else {
    module.exports = {
        BASE_URL: 'https://yt-champ.api.noicetools.com',
    }
}