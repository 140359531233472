import { useQuery } from "@tanstack/react-query";
import { getUserInfo } from "../../api/home";
import { IconButton } from "../../Components/Button";
import { Bell } from "lucide-react";
import DataKPIs from "./HomeSections/DataKPIs";
import TopVideosSection from "./HomeSections/TopVideos";

const HomeScreen = () => {


    const fetchData = async () => {
        let res = await getUserInfo();
        if (res === false) {
            return [];
        }
        return res.user;
    }

    const { data, isLoading } = useQuery({
        queryKey: ['userData'],
        queryFn: fetchData,
    });

    return (<>
        <div className="flex flex-col px-4">
            <div className="flex flex-row items-center justify-between md:mt-8">
                <div
                    className=" text-2xl text-slate-700 text-start"
                >
                    Home
                </div>
                <IconButton
                    isLoading={isLoading}
                >
                    <Bell
                        className="text-slate-600"
                    />
                </IconButton>
            </div>
            {/* <hr className="my-4" /> */}
            <div className="my-4" />
            <div className="flex flex-col content-center">
                <div className="text-2xl text-slate-700 font-medium">
                    Welcome back, {data?.name}
                </div>
                <div className="text-md text-slate-500">
                    Last updated: {
                        new Date(data?.last_update).toLocaleString({}, {
                            dateStyle: 'medium',
                        })
                    }
                </div>
            </div>
            <div className="my-4" />
            <DataKPIs
                data={data}
                isLoading={isLoading}
            />
            <div className="my-4" />
            <TopVideosSection />
        </div>
    </>)
};

export default HomeScreen;