import { toast } from "react-toastify";
import axiosClient from "./request";
import { CHANNELS, CHANNEL_DELETE, CREATE_CHANNEL, CHANNEL_DETAILS, CHANNEL_HISTORY } from "./endpoints";

export const getChannels = async ({
    page = 1,
}) => {
    try {
        const resp = await axiosClient.get(CHANNELS, {
            params: {
                page: page
            },
        });
        console.log(resp);
        if (!resp.data.success) {
            toast.error(resp?.data?.message ?? 'Something went wrong!');
        }
        return resp.data;
    } catch (error) {
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }
}

export const deleteChannel = async (id) => {
    try {
        const resp = await axiosClient.post(CHANNEL_DELETE.replace(':id', id));
        if (resp?.status === 200) {
            toast.success(resp?.data?.message ?? 'Channel deleted successfully!')
            return resp?.data;
        }
        toast.error(resp?.data?.message ?? 'Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    } catch (error) {
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }
}

export const createNewChannel = async ({
    url,
}) => {
    try {
        const resp = await axiosClient.post(CREATE_CHANNEL, {
            url: url
        });
        if (resp?.status === 200) {
            toast.success(resp?.data?.message ?? 'Channel created successfully!')
            return resp?.data;
        }
        toast.error(resp?.data?.message ?? 'Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    } catch (error) {
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }
}

export const getChannelDetails = async (id) => {
    try {
        const resp = await axiosClient.get(CHANNEL_DETAILS.replace(':id', id));
        if (resp?.status === 200) {
            return resp?.data;
        }
        toast.error(resp?.data?.message ?? 'Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    } catch (error) {
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }
}

export const getChannelHistory = async (id) => {
    try {
        const resp = await axiosClient.get(CHANNEL_HISTORY.replace(':id', id));
        if (resp?.status === 200) {
            return resp?.data;
        }
        toast.error(resp?.data?.message ?? 'Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    } catch (error) {
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }
}