import { toast } from "react-toastify";
import axiosClient from "./request";
import { VIDEOS, TOP_VIDEOS } from "./endpoints";

export const getVideos = async () => {
    // get query params from url
    const params = new URLSearchParams(window.location.search);
    try {
        const resp = await axiosClient.get(VIDEOS,
            {
                params: params,
            });
        if (!resp.data.success) {
            toast.error(resp?.data?.message ?? 'Something went wrong!');
        }
        return resp.data;
    }
    catch (error) {
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }

}

export const getTopVideos = async () => {
    try {
        const resp = await axiosClient.get(TOP_VIDEOS);
        if (!resp?.data?.success) {
            toast.error(resp?.data?.message ?? 'Something went wrong!');
            if (!resp.data) {
                return {
                    success: false,
                    data: {},
                    message: 'Something went wrong!'
                }
            }
        }
        return resp.data;
    }
    catch (error) {
        console.log(error);
        toast.error('Something went wrong!');
        return {
            success: false,
            data: {},
            message: 'Something went wrong!'
        };
    }
};