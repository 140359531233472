import { Link, useParams } from "react-router-dom";
import { getChannelDetails } from "../../../../api/channels";
import { useQuery } from "@tanstack/react-query";
import { ExternalLink } from "lucide-react";


export default function ChannelDetails() {
    const { id } = useParams();

    const getChannel = async () => {
        let resp = await getChannelDetails(id);
        if (!resp.success) {
            console.log(resp.message);
            throw new Error(resp.message);
        }
        let data = resp.data;
        return data;
    }

    const { data } = useQuery({
        queryKey: ["channel", id],
        queryFn: getChannel,
        retry: false,
        staleTime: 3600 * 1000,
        placeholderData: {
            name: 'Loading...',
            statistics: {
                subscribers: 0,
                subNew: 0,
                views: 0,
                viewsNew: 0,
                videos: 0,
                videosNew: 0,
            }
        }
    });

    const toKMB = (num) => {
        if (num < 1000) return num;
        if (num < 1000000) return (num / 1000).toFixed(1) + 'K';
        if (num < 1000000000) return (num / 1000000).toFixed(1) + 'M';
        if (num < 1000000000000) return (num / 1000000000).toFixed(1) + 'B';
        return Math.floor(num / 1000000000000) + 'T';
    }

    return (<>
        <div className="flex flex-col justify-between items-start md:flex-row w-full px-4">
            <div className="flex flex-row items-center rounded-lg py-4 ">
                <div
                    className="rounded-full shadow-lg h-40 w-40"
                >
                    <img src={data?.thumbnail} alt={data?.className} className="rounded-full shadow-lg shadow-purple-200 h-40 w-40" />
                </div>
                <div className="flex flex-col justify-center">
                    <div
                        className="ml-3 mt-2 text-xl text-slate-600 font-semibold text-start text-ellipsis"
                        title={data?.name}
                    >
                        {data?.name}
                    </div>
                    <Link
                        to={data?.channel && `https://www.youtube.com/channel/${data?.channel}`}
                        target={data?.channel && "_blank"}
                        className="flex flex-row mr-auto items-center ml-3 mt-2 text-slate-600 px-2 py-1 rounded-md bg-purple-100 hover:bg-purple-200 cursor-pointer"
                        title={`View ${data?.name} on YouTube`}
                    >
                        <ExternalLink
                            size={18}
                        />
                        <div className="text-xs ml-1">View on YouTube</div>
                    </Link>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row text-start rounded-lg px-2 py-4 w-full md:w-auto sm:justify-around sm:items-center">
                <div>
                    <span
                        className="text-xl text-slate-700 font-semibold text-start"
                        title={data?.statistics?.subscribers.toLocaleString() + ' subscribers'}
                    >
                        {toKMB(data?.statistics?.subscribers ?? 0)}
                        {(data?.statistics?.subNew !== 0 && <span className={`text-sm font-semibold ${data?.statistics?.subNew < 0 ? 'text-red-600' : 'text-green-600'}`}>
                            ({data?.statistics?.subNew < 0 ? '' : '+'}{toKMB(data?.statistics?.subNew ?? 0)})
                        </span>)}
                    </span>
                    <br />
                    <span>
                        <span className="text-slate-600 font-semibold">Subscribers</span>
                    </span>
                </div>
                <div className="flex flex-row items-end h-auto sm:h-24 w-full sm:w-auto">
                    <div className="my-4 h-[0.5px] w-[60%] sm:h-[60%] sm:w-[0.5px] mx-2 md:mx-4 bg-purple-200" />
                </div>
                <div>
                    <span
                        className="text-xl text-slate-700 font-semibold text-start"
                        title={data?.statistics?.views.toLocaleString() + ' views'}
                    >
                        {toKMB(data?.statistics?.views ?? 0)}

                        {(data?.statistics?.viewsNew !== 0 && <span className={` text-sm font-semibold ${data?.statistics?.viewsNew < 0 ? 'text-red-600' : 'text-green-600'}`}>
                            ({data?.statistics?.viewsNew < 0 ? '' : '+'}{toKMB(data?.statistics?.viewsNew ?? 0)})
                        </span>)}
                    </span>
                    <br />
                    <span>
                        <span className="text-slate-600 font-semibold">Views</span>
                    </span>
                </div>
                <div className="flex flex-row items-end h-auto sm:h-24 w-full sm:w-auto">
                    <div className="my-4 h-[0.5px] w-[60%] sm:h-[60%] sm:w-[0.5px] mx-2 md:mx-4 bg-purple-200" />
                </div>
                <div>
                    <span
                        className="text-xl text-slate-700 font-semibold text-start"
                        title={data?.statistics?.videos.toLocaleString() + ' videos'}
                    >
                        {toKMB(data?.statistics?.videos ?? 0)}

                        {(data?.statistics?.videosNew !== 0 && <span className={` text-sm font-semibold ${data?.statistics?.videosNew < 0 ? 'text-red-600' : 'text-green-600'}`}>
                            ({data?.statistics?.videosNew < 0 ? '' : '+'}{toKMB(data?.statistics?.videosNew ?? 0)})
                        </span>)}
                    </span>
                    <br />
                    <span>
                        <span className="text-slate-600 font-semibold">Videos</span>
                    </span>
                </div>
            </div>
        </div>
    </>);
}