import { Loader2 } from "lucide-react";

const Button = ({ children, className, isLoading, isPrimary = true, ...props }) => {
    return (!isLoading ? <button
        className={` ${isPrimary ? "bg-slate-900 text-white" : "bg-slate-200 text-slate-800"} shadow-none hover:shadow-slate-300 hover:shadow-xl active:scale-95 active:shadow-none font-semibold py-2 px-4 rounded-lg duration-300 ${className}`}
        {...props}
    >
        {children}
    </button> : <button
        className={`${isPrimary ? "text-white bg-slate-700" : "text-slate-800 bg-slate-100"} shadow-transparent font-semibold scale-95 py-2 px-4 rounded-lg duration-300 ${className}`}
        {...props}
        disabled
    >
        <Loader2
            className=" m-auto animate-spin"
        ></Loader2>
    </button>)
};

export const ButtonSmall = ({ children, className, isLoading, isPrimary = true, ...props }) => {
    return (!isLoading ? <button
        className={`text-sm ${isPrimary ? "bg-slate-900 text-white" : "bg-slate-200 text-slate-800"} active:scale-95 active:shadow-none font-medium py-2 px-4 rounded-lg duration-300 ${className}`}
        {...props}
    >
        {children}
    </button> : <button
        className={`text-sm ${isPrimary ? "text-white bg-slate-700" : "text-slate-800 bg-slate-100"} shadow-transparent font-medium scale-95 py-2 px-4 rounded-lg duration-300 ${className}`}
        {...props}
        disabled
    >
        <Loader2
            className=" m-auto animate-spin"
        ></Loader2>
    </button>)
}

export const IconButton = ({ children, isLoading, ...props }) => {
    return (!isLoading ? <button
        className="rounded-full p-2 hover:bg-slate-200 duration-200"
        {...props}
    >
        {children}
    </button> : <button
        className="rounded-full p-2 bg-slate-100 duration-200"
        {...props}
        disabled
    >
        <Loader2
            className="animate-spin"
        ></Loader2>
    </button>)
};

export default Button;