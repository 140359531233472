import { useState } from "react";
import Button from "../../../Components/Button";
import Backdrop from "../../../Components/Backdrop";
import { createNewChannel } from "../../../api/channels";

export default function NewChannelButton() {
    const [open, setOpen] = useState(false);
    const [channelURL, setChannelURL] = useState("");
    const [loading, setLoading] = useState(false);

    return (<>
        <Button
            onClick={() => {
                setOpen(true);
                setChannelURL("");
            }}
        >
            Add Channel
        </Button>
        <Backdrop
            isOpen={open}
            setOpen={setOpen}
        />
        <div
            className={`flex flex-col fixed bg-white rounded-lg shadow-lg p-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-5/6 md:w-1/2 lg:w-1/3 duration-300 ${open ? "opacity-100 visible" : "opacity-0 invisible -translate-y-2"}`}
        >
            <form
                className="flex flex-col"
                onSubmit={(e) => {
                    e.preventDefault();
                    // setOpen(false);
                    setLoading(true);
                    createNewChannel({
                        url: channelURL
                    }).then((data) => {
                        if (data.success) {
                            setOpen(false);
                            setLoading(false);
                        }
                        else {
                            setLoading(false);
                        }
                    }).catch((data) => {
                        setLoading(false);
                    });
                }}
            >
                <span className="font-semibold text-start text-lg mb-2">
                    Add New Channel
                </span>
                <input
                    className="border border-gray-300 rounded-lg p-1 mb-2 w-full resize-none focus:outline-none"
                    required
                    type="text"
                    value={channelURL}
                    onChange={(e) => setChannelURL(e.target.value)}
                    // rows={5}
                    placeholder="https://www.youtube.com/channel/UC-lHJZR3Gqxm24_Vd_AJ5Yw"
                ></input>
                <p className="text-sm text-start">
                    You can add new channels here to be monitored. To add new channel, write channel URL
                    {/* s separated by new line. */}
                </p>
                <div className="flex flex-row justify-end mt-4 mb-2">
                    <Button
                        className="mr-2"
                        isPrimary={false}
                        type="reset"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        isPrimary={true}
                        isLoading={loading}
                        type="submit"
                    >
                        Add
                    </Button>
                </div>
            </form>
        </div>
    </>);
}