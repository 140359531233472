import { Search, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom"
import Backdrop from "./Backdrop";
// import LabeledCheckbox from "./Checkbox";
import { ButtonSmall } from "./Button";
import { useQuery } from "@tanstack/react-query";
import { getChannels } from "../api/channels";


export const SearchPopup = ({
    ...props
}) => {
    const [queryParams, setQueryParams] = useSearchParams();
    const [searchOpen, setSearchOpen] = useState(false);
    const [ordering, setOrdering] = useState(queryParams.get("ordering") || "newViews");

    const [searchChannel, setSearchChannel] = useState("");

    const [filters, setFilters] = useState({
        search: queryParams.get("search") || "",
        min_views: parseInt(queryParams.get("min_views")) || 0,
        max_views: parseInt(queryParams.get("max_views")) || 0,
        channel: queryParams.getAll("channel") || [],
    });
    const searchRef = useRef(null);
    const channelSelectRef = useRef(null);

    const { data: channels, isLoading: channelsLoading } = useQuery({
        queryKey: ["channels"],
        queryFn: async () => {
            let res = await getChannels({ page: 1 });
            return res.data.results;
        }
    })

    function search() {
        setSearchOpen(false);
        let params = {};
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                params[key] = value;
            }
        }
        if (ordering) {
            params["ordering"] = ordering;
        }

        console.log(params);
        setQueryParams({ ...params });
    }

    useEffect(() => {
        // start search on CTRL+K
        const handleCtrlK = (event) => {
            if (event.ctrlKey && event.keyCode === 75) {
                event.preventDefault();
                setSearchOpen(true);
                setTimeout(() => {
                    searchRef.current.focus();
                }, 100);
            }
        };
        window.addEventListener("keydown", handleCtrlK);
        return () => {
            window.removeEventListener("keydown", handleCtrlK);
        };
    });

    return (
        <>
            <div className="text-gray-600">
                <button
                    className='mr-8 p-2 rounded-full cursor-pointer hover:bg-slate-200 duration-200'
                    onClick={() => {
                        setSearchOpen(true);
                        // setSearchValue(queryParams.get("search") || "");
                        setFilters({
                            ...filters,
                            search: queryParams.get("search") || ""
                        });
                        setTimeout(() => {
                            searchRef.current.focus();
                        }, 100);
                    }}
                >
                    <Search size={18} className="text-slate-500" />
                </button>
            </div>
            <Backdrop
                isOpen={searchOpen}
                setOpen={setSearchOpen}
            >
                <div
                    className="fixed w-3/4 md:w-2/4 lg:w-1/3 top-44 left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-lg duration-200 py-2"
                >
                    <div
                        className="flex flex-row justify-between items-center mx-4 mb-2"
                    >
                        <div
                            className="flex flex-row w-full mr-5 items-center"
                        >
                            <Search size={18} className="text-slate-500" />
                            <form
                                className="w-full"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    search();
                                }}
                            >
                                <input
                                    className="ml-2 w-full focus:outline-none py-2"
                                    placeholder="Search"
                                    value={filters.search}
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                    id="search"
                                    ref={searchRef}
                                />
                            </form>
                        </div>
                        <button
                            className={`p-2 rounded-full cursor-pointer hover:bg-slate-200 duration-200 ${filters.search ? "visible opacity-100" : "invisible opacity-0"}`}
                            onClick={() => {
                                setFilters({ ...filters, search: "" });
                            }}
                        >
                            <X size={18} className="text-slate-500" />
                        </button>
                    </div>
                    <hr />
                    <div
                        className="flex flex-col m-4"
                    >
                        <div
                            className="text-gray-600 mb-2 text-start"
                        >
                            Sort by
                        </div>
                        <div
                            className="flex flex-row ml-2"
                        >
                            <div
                                className="flex flex-row items-center"
                            >
                                <input
                                    type="radio"
                                    id="newest"
                                    name="ordering"
                                    value="-video__uploaded"
                                    checked={ordering === "-video__uploaded"}
                                    onChange={(e) => setOrdering(e.target.value)}
                                />
                                <label
                                    htmlFor="newest"
                                    className="ml-2"
                                >
                                    Newest
                                </label>
                            </div>
                            <div
                                className="flex flex-row items-center ml-4"
                            >
                                <input
                                    type="radio"
                                    id="newViews"
                                    name="ordering"
                                    value="-newViews"
                                    checked={ordering === "-newViews"}
                                    onChange={(e) => setOrdering(e.target.value)}
                                />
                                <label
                                    htmlFor="newViews"
                                    className="ml-2"
                                >
                                    Views
                                    <span className="text-xs text-gray-400">
                                        (in 24hrs)
                                    </span>
                                </label>
                            </div>
                            {/* views in total */}
                            <div
                                className="flex flex-row items-center ml-4"
                            >
                                <input
                                    type="radio"
                                    id="totalViews"
                                    name="ordering"
                                    value="-views"
                                    checked={ordering === "-views"}
                                    onChange={(e) => setOrdering(e.target.value)}
                                />
                                <label
                                    htmlFor="totalViews"
                                    className="ml-2"
                                >
                                    Total views
                                </label>
                            </div>
                            {/* random */}
                            <div
                                className="flex flex-row items-center ml-4"
                            >
                                <input
                                    type="radio"
                                    id="random"
                                    name="ordering"
                                    value="?"
                                    checked={ordering === "?"}
                                    onChange={(e) => setOrdering(e.target.value)}
                                />
                                <label
                                    htmlFor="random"
                                    className="ml-2"
                                >
                                    Random
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center mt-4">
                            <label
                                htmlFor="newViews"
                                className="text-gray-600 mb-2 text-start whitespace-nowrap translate-y-[3px]"
                            >
                                Minimum views
                            </label>
                            <input
                                id="newViews"
                                name="newViews"
                                value={filters.min_views ? filters.min_views.toLocaleString() : ""}
                                onChange={(e) => {
                                    let text = e.target.value;
                                    console.log(text)
                                    text = text.replaceAll(',', '');
                                    text = text.replace('.', '');
                                    text = text.replace(' ', '');
                                    text = text.replace('k', '000');
                                    text = text.replace('m', '000000');
                                    text = text.replace('b', '000000000');
                                    console.log(text)
                                    try {
                                        let number = parseInt(text);
                                        if (isNaN(number)) {
                                            number = 0;
                                        }
                                        if (number > 1000000000) {
                                            number = 1000000000;
                                        }
                                        setFilters({ ...filters, min_views: number });
                                    }
                                    catch (e) { }
                                }}
                                step={1000}
                                className="ml-2 bg-slate-100 rounded-lg w-auto focus:outline-none py-1 px-2 text-right"
                            />
                        </div>
                        {/* <div className="flex items-center mt-4">
                            <label
                                htmlFor="newViews"
                                className="text-gray-600 mb-2 text-start whitespace-nowrap translate-y-[3px]"
                            >
                                Maximum views
                            </label>
                            <input
                                id="newViews"
                                name="newViews"
                                value={filters.max_views ? filters.max_views.toLocaleString() : ""}
                                onChange={(e) => {
                                    let text = e.target.value;
                                    console.log(text)
                                    text = text.replaceAll(',', '');
                                    text = text.replace('.', '');
                                    text = text.replace(' ', '');
                                    text = text.replace('k', '000');
                                    text = text.replace('m', '000000');
                                    text = text.replace('b', '000000000');
                                    console.log(text)
                                    try {
                                        let number = parseInt(text);
                                        if (isNaN(number)) {
                                            number = 0;
                                        }
                                        if (number > 1000000000) {
                                            number = 1000000000;
                                        }
                                        setFilters({ ...filters, max_views: number });
                                    }
                                    catch (e) { }
                                }}
                                step={1000}
                                className="ml-2 bg-slate-100 rounded-lg w-auto focus:outline-none py-1 px-2 text-right"
                            />
                        </div> */}
                        {/* multi select */}
                        <div
                            className="flex flex-col items-start mt-4"
                        >
                            <div
                                className="text-gray-600 mb-2 text-start"
                            >
                                Channels
                            </div>
                            <div
                                className="flex flex-col w-full ml-2"
                            >
                                <div
                                    className="w-full flex flex-row overflow-x-auto items-center bg-slate-50 rounded-t-lg px-2 py-1"
                                >
                                    {channelsLoading && <div>Loading...</div>}
                                    {filters.channel?.map(channel => (
                                        <div
                                            key={channel}
                                            className="flex flex-row items-center bg-red-500 text-white rounded-full px-2 py-1 ml-1 mt-1"
                                        >
                                            <span className="whitespace-nowrap text-xs">
                                                {channels.find(c => c.channel === channel)?.name || channel}
                                            </span>
                                            <button
                                                onClick={() => {
                                                    let selected = filters.channel.filter(c => c !== channel);
                                                    setFilters({ ...filters, channel: selected });
                                                }}
                                                className="ml-2"
                                            >
                                                <X size={18} className="text-white" />
                                            </button>
                                        </div>
                                    ))}
                                    <input
                                        className="p-2 bg-transparent focus:outline-none"
                                        placeholder="Search channel"
                                        value={searchChannel}
                                        onChange={(e) => setSearchChannel(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (!searchChannel) {
                                                if (e.key === "Backspace") {
                                                    let selected = filters.channel;
                                                    selected.pop();
                                                    setFilters({ ...filters, channel: selected });
                                                }
                                            }
                                            if (e.key === 'ArrowDown') {
                                                channelSelectRef.current.focus();
                                            }
                                        }}
                                    />
                                </div>
                                {channels && (
                                    <div
                                        ref={channelSelectRef}
                                        tabIndex={0}
                                        className="flex flex-wrap max-h-48 w-full overflow-y-auto rounded-b-lg p-2 bg-slate-50 focus:outline-none"
                                    >
                                        {channels
                                            ?.filter(channel => channel?.name.toLowerCase().includes(searchChannel.toLowerCase()))
                                            .map(channel => (
                                                <button
                                                    key={channel.channel}
                                                    className={`items-center text-start rounded-md px-2 py-1 mx-1 mt-1 cursor-pointer duration-200 ${filters.channel.includes(channel.channel) ? 'bg-red-500 text-white' : 'bg-slate-100 hover:bg-slate-200 focus:bg-slate-200'}`}
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        let selected = filters.channel;
                                                        if (!selected.includes(channel.channel)) {
                                                            selected.push(channel.channel);
                                                        } else {
                                                            selected = selected.filter(c => c !== channel.channel);
                                                        }
                                                        setFilters({ ...filters, channel: selected });
                                                        setSearchChannel("");
                                                    }}
                                                >
                                                    <img
                                                        src={channel.thumbnail}
                                                        alt={channel.name}
                                                        className="h-4 w-4 rounded-full inline-block"
                                                    />
                                                    <span className="ml-2">
                                                        {channel.name}
                                                        <span
                                                            className="text-xs font-semibold"
                                                        >
                                                            ({toKMB(channel.channeldata_set?.subscribers || 0)})
                                                        </span>
                                                    </span>
                                                </button>
                                            ))}
                                    </div>)}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-row justify-end items-center gap-2 mt-4 mr-2">
                        <ButtonSmall
                            isPrimary={false}
                            onClick={() => {
                                setFilters({
                                    search: "",
                                    min_views: 0,
                                    max_views: 0,
                                    channel: [],
                                });
                                setOrdering("-newViews");
                            }}
                        >
                            Clear
                        </ButtonSmall>
                        <ButtonSmall
                            onClick={() => {
                                search();
                            }}
                        >
                            Search
                        </ButtonSmall>
                    </div>
                </div>
            </Backdrop>
        </>
    );
}

const toKMB = (num) => {
    if (num < 1000) return num;
    if (num < 1000000) return (num / 1000).toFixed(1) + 'K';
    if (num < 1000000000) return (num / 1000000).toFixed(1) + 'M';
    if (num < 1000000000000) return (num / 1000000000).toFixed(1) + 'B';
    return Math.floor(num / 1000000000000) + 'T';
}