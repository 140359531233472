import { Outlet, useNavigate } from "react-router-dom";
import SideMenuItem from "../../Components/NavItem";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { LayoutDashboard, LogOut, Menu, ScanSearch, Tv2, Youtube } from "lucide-react";
import { logout } from "../../state/auth/slice";


const navItems = [
    {
        name: "Dashboard",
        icon: <LayoutDashboard />,
        link: "/"
    },
    {
        name: "Videos",
        icon: <Youtube />,
        link: "/videos"
    },
    {
        name: "Channels",
        icon: <Tv2 />,
        link: "/channels"
    },
    {
        name: "Find Competitors",
        icon: <ScanSearch />,
        link: "/research"
    }
]

const LayoutPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [sideBarOpen, setSideBarOpen] = useState(false);
    return (
        <div
            className="flex flex-row w-full h-screen"
        >
            <div className={`lg:w-72 border-gray-100 lg:relative absolute w-80 ${sideBarOpen ? "left-0" : " -left-80"} transition-all duration-500 ease-in-out lg:left-0 z-20 h-[100dvh]`}>
                <div className="flex flex-col h-full bg-white justify-between">
                    <div className="flex flex-col justify-center mt-4">
                        <div
                            className="text-2xl text-gray-700 text-center mt-2 mb-8"
                        >
                            <span
                                className="text-red-500"
                            >
                                Vid
                            </span>
                            Champ
                        </div>
                        {navItems.map((item, index) => (
                            <SideMenuItem
                                key={index}
                                active={item.link === window.location.pathname}
                                icon={item.icon}
                                onClick={() => {
                                    setTimeout(() => {
                                        setSideBarOpen(false);
                                    }, 300);
                                    navigate(item.link);
                                }}
                            >
                                <span className="ml-1">{item.name}</span>
                            </SideMenuItem>
                        ))}
                    </div>
                    <div className="flex flex-col justify-center mb-4">
                        <SideMenuItem
                            active={false}
                            icon={<LogOut />}
                            onClick={() => {
                                setSideBarOpen(false);
                                dispatch(logout());
                                navigate("/");
                            }}
                        >
                            <span className="ml-1">Logout</span>
                        </SideMenuItem>
                    </div>
                </div>
            </div>
            <div
                className="w-[0.5px] my-8 bg-gray-100"
            ></div>
            <div className="flex flex-col w-full h-[100dvh] overflow-y-auto overflow-x-hidden">
                <div className="flex flex-row justify-between items-center px-4 py-2 lg:hidden">
                    <button>
                        <Menu
                            size={32}
                            onClick={() => setSideBarOpen(!sideBarOpen)}
                        />
                    </button>
                </div>
                <Outlet />
                <div
                    className={`w-full h-full bg-slate-500 opacity-25 fixed top-0 left-0 ${sideBarOpen ? "block" : "hidden"}`}
                    onClick={() => setSideBarOpen(false)}
                ></div>
            </div>
        </div>
    )
}

export default LayoutPage;