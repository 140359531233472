import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authReducer from "./auth/slice";

const reducer = combineReducers({
    auth: authReducer,
});

export const store = configureStore({
    reducer,
});

export default reducer;
