
export function KpiCard({
    icon,
    title,
    value,
    action,
    children = null,
    progressBar = null,
    ...props
}) {
    return (
        <div
            className="flex flex-col flex-grow lg:flex-grow-0 rounded-lg w-80 p-4 border-[1px] border-slate-200"
        >
            <div
                className="flex flex-row justify-between items-center"
            >
                <div className="flex flex-row text-md font-medium text-slate-700 text-start">
                    <span className="text-red-500 mr-2">
                        {icon}
                    </span>
                    {title}
                </div>
                {action}
            </div>
            {
                progressBar == null ?
                    <div className="my-4" /> :
                    <div className="my-3" />
            }
            <div className="flex flex-row justify-between">
                <div className="text-lg font-semibold text-slate-700">
                    {value}
                </div>
                {children}
            </div>
            {progressBar}
        </div>
    )
}

export function KpiAction({
    icon,
    bgColor,
    color,
    hoverBgColor,
    tooltip,
    onClick,
    ...props
}) {
    return (
        <div
            className={`relative group p-1 rounded cursor-pointer hover:${hoverBgColor} duration-200 ${bgColor} ${color}`}
            onClick={onClick}
            {...props}
        >
            {icon}
            <div
                className="text-xs rounded-lg bg-slate-800 px-2 py-1 text-center text-white absolute w-max-60 whitespace-nowrap right-8 top-5 group-hover:visible group-hover:opacity-100 group-hover:top-0 invisible opacity-0 duration-200 "
            >
                {tooltip}
            </div>
        </div>
    )
}