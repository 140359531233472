import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { deleteChannel, getChannels } from '../../../api/channels';
import { Loader2, MoveDown, MoveUp } from 'lucide-react';
import { Table, TableBody, TableHeader, TableDataCell, LoadingTableDataCell, TableHeaderCell, TableRow, TableOptionCell, TableDropdownAction } from '../../../Components/Table/table';
import { Link } from 'react-router-dom';
import Button from '../../../Components/Button';
import NewChannelButton from '../ChannelSections/NewChannel';

const Channels = () => {

    const [records, setRecords] = React.useState([]);
    const [sortReverse, setSortReverse] = React.useState(false);
    const [sort, setSort] = React.useState(null);
    const [dropdown, setDropdown] = React.useState('');

    function sorter(name) {
        if (sort === name) {
            setSortReverse(!sortReverse);
        }
        else {
            setSortReverse(true);
        }
        setSort(name);
    }

    const sortRecords = (records, sort) => {
        if (sort === null) {
            return records;
        }
        let sortedRecords = [...records];
        sortedRecords.sort((a, b) => {
            if (sort === 'name') {
                return a.name.localeCompare(b.name);
            }
            else if (sort === 'subscribers') {
                return a.channeldata_set.subscribers - b.channeldata_set.subscribers;
            }
            else if (sort === 'views') {
                return a.channeldata_set.views - b.channeldata_set.views;
            }
            else if (sort === 'uploads') {
                return a.channeldata_set.videos - b.channeldata_set.videos;
            }
            else if (sort === 'subNew') {
                return a.channeldata_set.subNew - b.channeldata_set.subNew;
            }
            else if (sort === 'viewsNew') {
                return a.channeldata_set.viewsNew - b.channeldata_set.viewsNew;
            }
            else if (sort === 'uploadsNew') {
                return a.channeldata_set.videosNew - b.channeldata_set.videosNew;
            }
            else if (sort === 'created') {
                return a.created.localeCompare(b.created);
            }
            else {
                return 0;
            }
        });
        if (sortReverse) {
            sortedRecords.reverse();
        }
        return sortedRecords;
    }

    const fetchData = async ({
        pageParam = 1
    }) => {
        console.log('data', pageParam);
        const res = await getChannels({
            page: pageParam,
        });
        let results = res.data.results;
        sortRecords(results, sort);
        return results;
    }

    const { data, isLoading, refetch, isFetchingNextPage, hasNextPage, fetchNextPage } = useQuery({
        queryKey: ['channels'],
        queryFn: fetchData,
        staleTime: 3600 * 1000,
    });

    React.useEffect(() => {
        if (data) {
            setRecords(sortRecords(data, sort));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, sort, sortReverse]);

    return (
        <div>
            <div className="h-full px-4">
                <div className="flex flex-row items-center justify-between mt-8">
                    <div
                        className=" text-2xl text-slate-700 text-start"
                    >
                        Channels
                    </div>
                    <NewChannelButton />
                </div>
                <Table>
                    <TableHeader>
                        <TableHeaderCell
                            onClick={() => {
                                sorter('name');
                            }}
                        >
                            {sort === 'name' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            Name
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('subscribers') }}
                        >
                            {sort === 'subscribers' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            Subscribers
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('views') }}
                        >
                            {sort === 'views' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            Views
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('uploads') }}
                        >
                            {sort === 'uploads' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            Uploads
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('subNew') }}
                        >
                            {sort === 'subNew' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            New Subscribers
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('viewsNew') }}
                        >
                            {sort === 'viewsNew' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            New Views
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('uploadsNew') }}
                        >
                            {sort === 'uploadsNew' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            New Uploads
                        </TableHeaderCell>
                        <TableHeaderCell
                            onClick={() => { sorter('created') }}
                        >
                            {sort === 'created' && (
                                <span className="ml-1">
                                    {sortReverse ? <MoveDown size={12} className='inline-block' /> : <MoveUp size={12} className='inline-block' />}
                                </span>
                            )}
                            Created
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <span className="sr-only">
                                Actions
                            </span>
                        </TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {isLoading &&
                            (new Array(5)).fill(0).map((_, index) => {
                                return <TableRow key={index}>
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                </TableRow>
                            }
                            )}
                        {records?.length === 0 && !isLoading && (
                            <TableRow>
                                <TableDataCell colSpan={8} className="text-center py-4">
                                    <div className="flex flex-col items-center">
                                        <span className="text-slate-500">
                                            Please add channels to get started.<br />
                                            {"You can "}
                                            <Link
                                                to={'/research'}
                                                className={" text-blue-400 hover:text-blue-500"}
                                            >
                                                {"find competitors"}
                                            </Link>
                                            {" and save them to be monitored daily."}
                                        </span>
                                    </div>
                                </TableDataCell>
                            </TableRow>
                        )}

                        {records.map((row, index) => {
                            return <TableRow
                                className={"text-sm text-start text-slate-500"}
                            >
                                <TableDataCell
                                    className={"font-semibold"}
                                >
                                    <Link to={`/channel/${row.channel}`} className={"flex flex-row items-center"}>
                                        {row.name}
                                    </Link>
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.channeldata_set?.subscribers?.toLocaleString() ?? 'N/A'}
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.channeldata_set?.views?.toLocaleString() ?? 'N/A'}
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.channeldata_set?.videos?.toLocaleString() ?? 'N/A'}
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.channeldata_set?.subNew?.toLocaleString() ?? 'N/A'}
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.channeldata_set?.viewsNew?.toLocaleString() ?? 'N/A'}
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.channeldata_set?.videosNew?.toLocaleString() ?? 'N/A'}
                                </TableDataCell>
                                <TableDataCell>
                                    {row?.created && new Date(row?.created).toLocaleString({}, { dateStyle: "medium" })}
                                </TableDataCell>
                                <TableOptionCell
                                    dropdown={dropdown}
                                    setDropdown={setDropdown}
                                    key={index}
                                    keyId={row.channel}
                                    className={'right-10'}
                                >
                                    <Link
                                        to={`https://www.youtube.com/channel/${row.channel}`}
                                        target='_blank'
                                    >
                                        <TableDropdownAction>
                                            View On YouTube
                                        </TableDropdownAction>
                                    </Link>
                                    <TableDropdownAction
                                        onClick={async () => {
                                            const shouldDelete = window.confirm(`Are you sure you want to delete ${row.name}?\nNote: This will delete all data associated with this channel.`);
                                            if (shouldDelete) {
                                                await deleteChannel(row.channel);
                                                for (let i = 0; i < records.length; i++) {
                                                    if (records[i].channel === row.channel) {
                                                        records.splice(i, 1);
                                                        break;
                                                    }
                                                }
                                                setRecords([...records]);
                                                refetch();
                                            }
                                        }}
                                    >
                                        <span className=' text-red-600'>
                                            Delete Channel
                                        </span>
                                    </TableDropdownAction>
                                </TableOptionCell>
                            </TableRow>
                        })}
                        {hasNextPage &&
                            <TableRow>
                                <TableDataCell
                                    colSpan={8}
                                    className="text-center pt-4 pb-6"
                                >
                                    {isFetchingNextPage
                                        ?
                                        <Loader2
                                            className=' animate-spin m-auto'
                                        />
                                        :
                                        <div
                                            className='w-24 m-auto'
                                        >
                                            <Button
                                                onClick={fetchNextPage}
                                            >
                                                More
                                            </Button>
                                        </div>
                                    }
                                </TableDataCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        </div >
    )
}

export default Channels;