import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getVideos } from '../../../api/videos';
import { NavLink, useSearchParams } from 'react-router-dom';
import { SearchPopup } from '../../../Components/VideoSearchBar';
import { Table, TableBody, TableHeader, TableDataCell, LoadingTableDataCell, TableHeaderCell, TableRow, TableOptionCell, TableDropdownAction } from '../../../Components/Table/table';
import FilterChips from '../../../Components/FilterChips';
import { GalleryVerticalEnd, Loader2, Table2 } from 'lucide-react';
import VideoCard from '../../../Components/VideoCard';

const VideosPage = () => {
    const [dropdown, setDropdown] = React.useState('');
    const [tableView, setTableView] = React.useState(false);

    // query params
    const [queryParams, setQueryParams] = useSearchParams();

    const setSort = (value) => {
        queryParams.set('ordering', value);
        setQueryParams(queryParams);
    }

    const fetchData = async () => {
        const res = await getVideos();
        let results = res.data.results;
        return results;
    }

    const { data, isLoading } = useQuery({
        queryKey: ['videos', ...queryParams.values()],
        queryFn: fetchData,
        staleTime: 1000 * 60 * 60,
        cacheTime: 1000 * 60 * 60,
        keepPreviousData: true,
    });

    return (
        <div>
            <div className="h-full w-full px-4">
                <div className="flex flex-row items-center justify-between mt-8">
                    <div
                        className=" text-2xl text-slate-700 text-start"
                    >
                        Videos {queryParams.get('search') && `for "${queryParams.get('search')}"`}
                    </div>
                    <SearchPopup />
                </div>
                <FilterChips />
                <div
                    className="flex flex-row items-center justify-end mt-4 mx-2 md:mx-6 lg:mx-8 -mb-4"
                >
                    <div
                        className="flex flex-row items-center"
                    >
                        <div
                            className="flex overflow-hidden bg-white border divide-x rounded-lg"
                        >
                            <button
                                onClick={() => setTableView(false)}
                                className={`px-3 py-2 ${!tableView ? 'bg-slate-900 text-white' : 'text-slate-500'}`}
                                title='Card View'
                            >
                                <GalleryVerticalEnd
                                    size={18}
                                />
                            </button>
                            <button
                                onClick={() => setTableView(true)}
                                className={`px-3 py-2 ${tableView ? 'bg-slate-900 text-white' : 'text-slate-500'}`}
                                title='Table View'
                            >
                                <Table2
                                    size={18}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                {
                    tableView ?
                        (<Table>
                            <TableHeader>
                                <TableHeaderCell>
                                    Title
                                </TableHeaderCell>
                                <TableHeaderCell
                                    onClick={() => setSort('-views')}
                                >
                                    Views
                                </TableHeaderCell>
                                <TableHeaderCell
                                    onClick={() => setSort('-likes')}
                                >
                                    Likes
                                </TableHeaderCell>
                                <TableHeaderCell
                                    onClick={() => setSort('-comments')}
                                >
                                    Comments
                                </TableHeaderCell>
                                <TableHeaderCell
                                    className={' whitespace-nowrap'}
                                    onClick={() => setSort('-newViews')}
                                >
                                    New Views
                                </TableHeaderCell>
                                <TableHeaderCell
                                    className={' whitespace-nowrap'}
                                    onClick={() => setSort('-newLikes')}
                                >
                                    New Likes
                                </TableHeaderCell>
                                <TableHeaderCell
                                    className={' whitespace-nowrap'}
                                    onClick={() => setSort('-newComments')}
                                >
                                    New Comments
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Uploaded At
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <span className="sr-only">Actions</span>
                                </TableHeaderCell>
                            </TableHeader>
                            <TableBody>
                                {data?.length === 0 && !isLoading && (
                                    <TableRow>
                                        <TableDataCell colSpan={8} className="text-center py-4">
                                            <div className="flex flex-col items-center">
                                                <span className="text-slate-500">
                                                    No videos found for the search query.
                                                    {" You can "}
                                                    <NavLink
                                                        to={'/research'}
                                                        className={" text-blue-400 hover:text-blue-500"}
                                                    >
                                                        {"find competitors"}
                                                    </NavLink>
                                                    {" and save them to be monitored daily."}
                                                    <br />
                                                    <span className='font-semibold'>NOTE: If You have recently added channels, please wait for 24 hours for the data to be fetched.</span>
                                                </span>
                                            </div>
                                        </TableDataCell>
                                    </TableRow>
                                )}
                                {isLoading && (
                                    Array(8).fill().map((_, i) => (
                                        <TableRow key={i}>
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                            <LoadingTableDataCell />
                                        </TableRow>))
                                )}

                                {data?.map((video, index) => (
                                    <TableRow
                                        key={index}
                                        className={'text-start text-sm'}
                                    >
                                        <TableDataCell>
                                            {video.title}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {video?.videodata_set?.views?.toLocaleString()}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {video?.videodata_set?.likes?.toLocaleString()}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {video?.videodata_set?.comments?.toLocaleString()}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <span
                                                className={`${video.videodata_set.newViews > 0 ? 'text-green-500 font-semibold' : video.videodata_set.newViews === 0 ? '' : 'text-red-500 font-semibold'}`}
                                            >
                                                {video?.videodata_set?.newViews?.toLocaleString()}
                                            </span>
                                        </TableDataCell>
                                        <TableDataCell>
                                            <span
                                                className={`${video.videodata_set.newLikes > 0 ? 'text-green-500 font-semibold' : video.videodata_set.newLikes === 0 ? '' : 'text-red-500 font-semibold'}`}
                                            >
                                                {video?.videodata_set?.newLikes?.toLocaleString()}
                                            </span>
                                        </TableDataCell>
                                        <TableDataCell>
                                            <span
                                                className={`${video.videodata_set.newComments > 0 ? 'text-green-500 font-semibold' : video.videodata_set.newComments === 0 ? '' : 'text-red-500 font-semibold'}`}
                                            >
                                                {video?.videodata_set?.newComments?.toLocaleString()}
                                            </span>
                                        </TableDataCell>
                                        <TableDataCell>
                                            {new Date(video.uploaded).toLocaleString({}, {
                                                dateStyle: 'medium',
                                                timeStyle: 'short',
                                            })}
                                        </TableDataCell>
                                        <TableOptionCell
                                            dropdown={dropdown}
                                            setDropdown={setDropdown}
                                            keyId={video.vid}
                                            className={'right-10'}
                                        >
                                            <NavLink
                                                to={`https://www.youtube.com/watch?v=${video.vid}`}
                                                target="_blank"
                                            >
                                                <TableDropdownAction>
                                                    Watch on YouTube
                                                </TableDropdownAction>
                                            </NavLink>
                                        </TableOptionCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>)
                        : (
                            <div className='flex flex-col gap-2 mt-6 mx-2 md:mx-6 lg:mx-8'>
                                {data?.map((video, index) => {
                                    return <VideoCard
                                        key={index}
                                        data={video}
                                    />
                                })}
                                {
                                    data?.length === 0 && !isLoading && (
                                        <div className="flex flex-col items-center mt-48">
                                            <span className="text-slate-500">
                                                No videos found for the search query.
                                                {" You can "}
                                                <NavLink
                                                    to={'/research'}
                                                    className={" text-blue-400 hover:text-blue-500"}
                                                >
                                                    {"find competitors"}
                                                </NavLink>
                                                {" and save them to be monitored daily."}
                                                <br />
                                                <span className='font-semibold'>NOTE: If You have recently added channels, please wait for 24 hours for the data to be fetched.</span>
                                            </span>
                                        </div>
                                    )
                                }
                                {
                                    isLoading && (
                                        <div
                                            className='flex flex-col justify-center h-[60dvh]'
                                        >
                                            <Loader2
                                                size={32}
                                                className='mx-auto animate-spin'
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )
                }
                <div className=' h-7' ></div>
            </div>
        </div >
    )
}

export default VideosPage;