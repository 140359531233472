import { MoreVertical } from "lucide-react";


export function Table({
    ...props
}) {

    return (
        <div className="relative w-full sm:rounded-lg ">
            <div className="flex flex-col mt-6">
                <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-x-auto border border-slate-200 md:rounded-lg">
                        <table className="min-w-full divide-y divide-slate-200">
                            {props.children}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TableHeader({
    ...props
}) {
    return (
        <thead className="bg-slate-100">
            <tr>
                {props.children}
            </tr>
        </thead>
    );
}

export function TableBody({
    ...props
}) {
    return (
        <tbody className="bg-white divide-y divide-slate-200">
            {props.children}
        </tbody>
    );
}

export function TableRow({
    className,
    ...props
}) {
    return (
        <tr className={className}>
            {props.children}
        </tr>
    );
}

export function TableHeaderCell({
    onClick,
    className,
    ...props
}) {
    return (
        <th className={`px-6 py-3 text-xs font-semibold tracking-wider text-left text-slate-500 ${className ?? ''} ${onClick !== null ? 'cursor-pointer' : ''}`} onClick={onClick}>
            {props.children}
        </th>
    );
}

export function TableDataCell({
    className,
    onClick,
    ...props
}) {
    return (
        <td className={`px-6 py-4 whitespace-nowrap ${className ?? ''} ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick} {...props}>
            {props.children}
        </td>
    );
}

export function LoadingTableDataCell({
    className,
    ...props
}) {
    return (
        <td className={`px-6 py-4 whitespace-nowrap ${className}`} {...props}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-slate-200 rounded w-3/4"></div>
                </div>
            </div>
        </td>
    );
}

export function TableOptionCell({
    className,
    setDropdown,
    dropdown,
    key,
    keyId,
    ...props
}) {
    return (<td className="px-2 py-4 text-sm whitespace-nowrap text-start"
        //* custom dropdown 
        onMouseLeave={() => {
            setDropdown('');
        }}
    >
        <div
            className="p-2 h-8 w-8 rounded-full cursor-pointer hover:bg-slate-200 duration-200"
            onMouseEnter={() => {
                setDropdown(keyId);
            }}
        // hover
        >
            <MoreVertical size={16} className="text-slate-500" />
            <div
                className={`${className} flex flex-col absolute mt-2 py-2 w-48 rounded-md overflow-hidden shadow-xl shadow-slate-300 bg-white z-10 duration-200  ${dropdown === keyId ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                onClick={() => {
                    setDropdown('');
                }}
            >
                {props.children}
            </div>
        </div>
    </td>);
}

export function TableDropdownAction({
    onClick,
    setDropdown,
    ...props
}) {
    return (<button
        className='w-full px-4 py-2 text-sm text-left text-slate-500 hover:bg-slate-100 duration-200'
        onClick={onClick}
    >
        {props.children}
    </button>);
}