import { NavLink, useParams } from "react-router-dom";
import { researchResults, saveChannels } from "../../api/research";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Check, Minus } from "lucide-react";
import Button from "../../Components/Button";
import { toast } from "react-toastify";
import { LoadingTableDataCell, Table, TableBody, TableDataCell, TableDropdownAction, TableHeader, TableHeaderCell, TableOptionCell, TableRow } from "../../Components/Table/table";


export default function ResearchChannelsScreen() {
    const { id } = useParams();
    // const [currentPage, setCurrentPage] = useState(1);
    const [selected, setSelected] = useState([]);
    const [shiftPressed, setShiftPressed] = useState(false);
    const [dropdown, setDropdown] = useState('');

    const getData = async () => {
        const data = (await researchResults({ id })).data;
        return data.results;
    }

    const saveSelectedChannels = async () => {
        const resp = await saveChannels({ channels: selected, research_id: id });
        console.log(resp);
        if (resp.success) {
            toast.success("Channels added successfully.", {
                autoClose: 2000,
                pauseOnFocusLoss: false,
            });
            setSelected([]);
            let res = refetch();
            res.then((data) => {
                console.log(data);
                if (data.length === 0) {
                    // back
                    window.history.back();
                }
            })
        }
    }

    const { data, isLoading, refetch } = useQuery({
        queryKey: [{ id }],
        queryFn: getData,
    });


    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Shift') {
                setShiftPressed(true);
                console.log('shift pressed');
            }
        }
        const handleKeyUp = (e) => {
            if (e.key === 'Shift') {
                setShiftPressed(false);
                console.log('shift released');
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            console.log('removed');
        }
    }, []);


    return (
        <div>
            <div
                className="flex flex-row justify-between items-center"
            >
                <h1
                    className="text-2xl font-medium text-start mt-8 mb-4 ml-4"
                >
                    Research Results
                </h1>
                <div
                    className="mr-4"
                >
                    <Button
                        onClick={() => {
                            if (selected.length === 0) {
                                toast.error("Please select at least one channel.", {
                                    autoClose: 2000,
                                    pauseOnFocusLoss: false,
                                });
                                return;
                            }
                            saveSelectedChannels();
                            console.log(selected);
                        }}
                    >
                        Save Channels
                    </Button>
                </div>
            </div>
            <p
                className="text-start mb-4 ml-4"
            >
                {selected.length} selected
            </p>
            <div className=" m-4 ">
                <Table>
                    <TableHeader>
                        <TableHeaderCell
                            onClick={() => {
                                if ((selected?.length) === (data?.length ?? 0)) {
                                    setSelected([]);
                                } else {
                                    setSelected(data?.map((item) => item.chid));
                                }
                            }}
                        >
                            <div className="flex flex-row items-center">
                                <div className={`w-4 h-4 flex items-center justify-center border rounded duration-200 ${selected.length > 0 ? "bg-blue-500 border-blue-500" : "bg-transparent border-gray-400"}`} >
                                    {selected.length === data?.length && (
                                        <Check
                                            size={12}
                                            strokeWidth={2}
                                            className="text-white"
                                        />
                                    )}
                                    {selected.length > 0 && selected.length !== data?.length && (
                                        <div className="flex flex-row items-center justify-center">
                                            <Minus
                                                size={12}
                                                strokeWidth={2}
                                                className="text-white"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <div className="ml-2">
                                Channel
                            </div>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Subscribers
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Views
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Uploads
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Joined
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <span className="sr-only">Action</span>
                        </TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {(data?.length ?? 0) === 0 && (
                            isLoading ?
                                (<><TableRow>
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                    <LoadingTableDataCell />
                                </TableRow>
                                    <TableRow>
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                    </TableRow>
                                    <TableRow>
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                        <LoadingTableDataCell />
                                    </TableRow> </>) :
                                <tr>
                                    <td
                                        colSpan={7}
                                        className="text-center py-4"
                                    >
                                        <div className="flex flex-col items-center">
                                            <span className="text-slate-500">
                                                No results found.
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                        )}

                        {data?.map((item, index) => (
                            <TableRow
                                key={index}
                                className="hover:bg-slate-50 select-none"
                            >
                                <TableDataCell
                                    onClick={() => {
                                        // handle if SHIFT key is pressed
                                        if (selected.length > 0 && (shiftPressed)) {
                                            const lastSelected = selected[selected.length - 1];
                                            const lastIndex = data.findIndex((item) => item.chid === lastSelected);
                                            const currentIndex = data.findIndex((dt) => dt.chid === item.chid);
                                            const start = Math.min(lastIndex, currentIndex);
                                            const end = Math.max(lastIndex, currentIndex);
                                            setSelected(data.slice(start, end + 1).map((item) => item.chid));
                                            return;
                                        }
                                        if (selected.includes(item.chid)) {
                                            setSelected(selected.filter((dt) => dt !== item.chid));
                                        } else {
                                            setSelected([...selected, item.chid]);
                                        }
                                    }}
                                >
                                    <div className="flex flex-row items-center">
                                        <div className={`w-4 h-4 flex items-center justify-center border rounded duration-200 ${selected.includes(item.chid) ? "bg-blue-500 border-blue-500" : "bg-transparent border-gray-400"}`} >
                                            {selected.includes(item.chid) && (
                                                <Check
                                                    size={12}
                                                    strokeWidth={2}
                                                    className="text-white"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </TableDataCell>
                                <TableDataCell>
                                    <div className="ml-2">
                                        <div className="flex flex-row items-center">
                                            <img
                                                src={item.thumbnail}
                                                alt=""
                                                className="h-8 w-8 rounded-full"
                                            />
                                            <div className="ml-2">
                                                {item.name}
                                            </div>
                                        </div>
                                    </div>
                                </TableDataCell>
                                <TableDataCell>
                                    {item.subs?.toLocaleString()}
                                </TableDataCell>
                                <TableDataCell>
                                    {item.views?.toLocaleString()}
                                </TableDataCell>
                                <TableDataCell>
                                    {item.uploads?.toLocaleString()}
                                </TableDataCell>
                                <TableDataCell
                                    className="text-slate-500 text-start text-sm"
                                >
                                    {new Date(item.createdon)?.toLocaleString({}, {
                                        dateStyle: 'medium',
                                    })}
                                </TableDataCell>
                                <TableOptionCell
                                    dropdown={dropdown}
                                    setDropdown={setDropdown}
                                    keyId={item.chid}
                                    className={'right-20'}
                                >
                                    <NavLink
                                        to={`https://www.youtube.com/channel/${item.chid}`}
                                        target="_blank"
                                    >
                                        <TableDropdownAction>
                                            View on YouTube
                                        </TableDropdownAction>
                                    </NavLink>
                                </TableOptionCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <p
                    className="text-start mt-4 mb-4"
                >
                    To add channels for monitoring select the channels and click on save channels.
                </p>
            </div>
        </div>
    )
}