import { toast } from "react-toastify";
import axiosClient from "./request";
import { RESEARCH, CREATE_RESEARCH, RESEARCH_CHANNELS, SAVE_CHANNELS, DELETE_RESEARCH } from "./endpoints";

const handleResponse = (resp) => {
    console.log(resp);
    if (!resp.data.success) {
        toast.error(resp?.data?.message ?? 'Something went wrong=!');
    }
    return resp.data;
};

const handleError = () => {
    toast.error('Something went wrong!');
    return {
        success: false,
        data: {},
        message: 'Something went wrong!'
    };
};

export const getResearches = async ({
    page = 1,
}) => {
    try {
        const resp = await axiosClient.get(RESEARCH,
            {
                params: {
                    page: page
                },
            });
        return handleResponse(resp);
    } catch (error) {
        return handleError();
    }
}

export const newResearch = async (data) => {
    try {
        const resp = await axiosClient.post(CREATE_RESEARCH,
            {
                ...data,
            });
        return handleResponse(resp);
    } catch (error) {
        return handleError();
    }
}

export const researchResults = async ({ id, ...queryParams }) => {
    try {
        const resp = await axiosClient.get(RESEARCH_CHANNELS.replace(':id', id),
            {
                params: queryParams,
            });
        return handleResponse(resp);
    } catch (error) {
        return handleError();
    }
}

export const saveChannels = async ({ channels, research_id }) => {
    try {
        const resp = await axiosClient.post(SAVE_CHANNELS,
            {
                channels: channels,
                research_id: research_id
            });
        return handleResponse(resp);
    } catch (error) {
        return handleError();
    }
}

export const deleteResearch = async (id) => {
    try {
        const resp = await axiosClient.post(DELETE_RESEARCH.replace(':id', id), {});
        return handleResponse(resp);
    } catch (error) {
        return handleError();
    }
}