module.exports = {
    LOGIN: '/login/',
    STATUS: '',
    RESEARCH: '/queues/',
    RESEARCH_CHANNELS: '/queue/:id/channels/',
    CREATE_RESEARCH: '/new-queue/',
    DELETE_RESEARCH: '/queue/:id/delete/',
    SAVE_CHANNELS: '/add-research-channels/',
    CHANNELS: '/channels/',
    CREATE_CHANNEL: '/add-new-channel/',
    CHANNEL_DELETE: '/channel/:id/remove/',
    CHANNEL_DETAILS: '/channel/:id/',
    CHANNEL_HISTORY: '/channel/:id/history/',
    VIDEOS: '/videos/',
    TOP_VIDEOS: '/top-videos/',
}