import './App.css';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginPage from './Screens/Auth/Login';
import LayoutPage from './Screens/Dashboard/Layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// screens
import VideosPage from './Screens/Dashboard/Sections/VideosPage';
import Research from './Screens/Dashboard/Sections/Research';
import Channels from './Screens/Dashboard/Sections/Channels';
import HomeScreen from './Screens/Dashboard/Home';

import { Provider, useSelector } from 'react-redux';
import { store } from './state/reducer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ResearchChannelsScreen from './Screens/SearchedChannels/ResearchedChannels';
import { Helmet } from 'react-helmet';
import ChannelPage from './Screens/Channel/ChannelPage';


const getRouter = ({ loggedIn }) => {
  if (loggedIn) {
    return (
      createBrowserRouter([
        {
          path: '/',
          element: <LayoutPage />,
          children: [
            {
              index: true,
              element: <HomeScreen />,
            },
            {
              path: '/videos',
              element: <VideosPage />,
            },
            // Channels
            {
              path: '/channels',
              element: <Channels />,
            },
            {
              path: '/channel/:id',
              element: <ChannelPage />,
            },

            // Research
            {
              path: '/research-channels/:id',
              element: <ResearchChannelsScreen />,
            },
            {
              path: '/research',
              element: <Research />,
            },
          ]
        }
      ])
    );
  }
  return (
    createBrowserRouter([
      {
        path: '*',
        element: <LoginPage />,
      }
    ])
  )
}

function App() {
  const auth = useSelector(state => state.auth);
  return (
    <div className="App font-serif bg-slate-510">
      <Helmet>
        <title>VidChamp</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        enableMultiContainer
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <RouterProvider router={getRouter({
        loggedIn: auth.loggedIn,
      })} />
    </div>
  );
}

const queryClient = new QueryClient();

const AppWrapper = () => {

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  )
}

export default AppWrapper;
