import { useEffect } from "react"

export default function Backdrop({
    isOpen,
    setOpen,
    setCloseOnBackdropClick = true,
    setCloseOnEsc = true,
    ...props
}) {
    useEffect(() => {
        // handle ESC key
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                if (setCloseOnEsc)
                    setOpen(false);
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    });

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full z-30 duration-200 ${isOpen ? "opacity-100 visible" : "opacity-0 invisible"} backdrop-blur-sm`}
        >
            <div
                className={`absolute top-0 left-0 w-full h-full bg-slate-900 opacity-50`}
                onClick={setCloseOnBackdropClick ? () => setOpen(false) : null}
            ></div>
            {props.children}
        </div>
    )
}