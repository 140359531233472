import { BarChartBig, MoreVertical, Youtube } from "lucide-react";
import { useState } from "react";
import Backdrop from "./Backdrop";
import { useQuery } from "@tanstack/react-query";
import { getChannelDetails } from "../api/channels";
import { Link } from "react-router-dom";

export default function VideoCard({
    data,
    ...props
}) {
    const [showChannelDetails, setShowChannelDetails] = useState(false);
    return (<>
        <div className="flex flex-col relative md:flex-row rounded-lg border-[1px] border-slate-300">
            <Backdrop
                isOpen={showChannelDetails}
                setOpen={setShowChannelDetails}
            />
            <div className="flex-shrink-0 relative overflow-clip md:rounded-tr-none md:rounded-l-lg rounded-t-lg">
                <img className="w-full h-auto aspect-video md:h-[192px] md:w-[342px] object-cover" src={data?.thumbnail} alt="" />
                <div className="absolute bg-black text-white opacity-90 text-xs font-semibold px-2 py-1 rounded-lg bottom-8 right-2">
                    {calcText(data?.length)}
                </div>
            </div >
            <div
                className="absolute right-2 top-2 rounded-full bg-slate-800 md:bg-transparent p-1 cursor-pointer group focus:outline-none"
                tabIndex={1}
            >
                <MoreVertical
                    size={18}
                    className="text-white md:text-slate-700"
                />
                <div
                    className="invisible absolute z-40 top-[105%] right-0 opacity-0 translate-y-10 group-focus:visible group-focus:opacity-100 group-focus:translate-y-0 duration-300"
                >
                    <div
                        className="flex flex-col bg-white shadow-xl shadow-slate-100 border border-slate-100 rounded-md p-1 cursor-auto"
                    >
                        <Link
                            to={`https://www.youtube.com/watch?v=${data?.vid}`}
                            target="_blank"
                            onFocusCapture={() => window.open(`https://www.youtube.com/watch?v=${data?.vid}`, '_blank')}
                            className="flex flex-row items-center justify-start px-4 py-2 rounded-md cursor-pointer hover:bg-slate-100 duration-300"
                        >
                            <div className="text-sm text-slate-500 ml-2 whitespace-nowrap">
                                Watch on YouTube
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="flex flex-col flex-grow p-4 text-start">
                <div className="text-lg lg:text-xl text-slate-700 font-medium">
                    {data?.title}
                    {data?.length < 60 &&
                        <span className="text-xs text-white bg-red-600 rounded-lg px-2 py-1 ml-2">
                            Shorts
                        </span>
                    }
                </div>
                <div className=" flex flex-row items-center justify-start mt-2 font-medium">
                    <div className="text-sm lg:text-md text-slate-500">
                        {data?.videodata_set?.views.toLocaleString()}
                        (<span className={`text-sm lg:text-md font-semibold ${data?.videodata_set?.newViews > 0 ? 'text-green-500' : data?.videodata_set?.newViews < 0 ? 'text-red-500' : 'text-slate-500'}`}>
                            {data?.videodata_set?.newViews > 0 ?
                                "+"
                                :
                                null
                            }
                            {data?.videodata_set?.newViews.toLocaleString()}

                        </span>)

                        views
                    </div>
                    &nbsp;&middot;&nbsp;
                    <div className="text-sm lg:text-md text-slate-500">
                        {data?.videodata_set?.likes.toLocaleString()}
                        (
                        <span className={`text-sm lg:text-md font-semibold ${data?.videodata_set?.newLikes > 0 ? 'text-green-500' : data?.videodata_set?.newLikes < 0 ? 'text-red-500' : 'text-slate-500'}`}>
                            {data?.videodata_set?.newLikes > 0 ?
                                "+"
                                :
                                null
                            }
                            {data?.videodata_set?.newLikes.toLocaleString()}
                        </span>
                        )
                        likes
                    </div>
                    &nbsp;&middot;&nbsp;
                    <div className="text-sm lg:text-md text-slate-500">
                        {new Date(data?.uploaded).toLocaleString()} ({getRelativeDays(data?.uploaded)})
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between mt-4">
                    <div
                        className={`flex flex-row items-center px-2 py-1 rounded-lg cursor-pointer hover:bg-slate-100 duration-300 focus:bg-slate-100 group relative ${showChannelDetails ? 'popup-open' : ''}`}
                        onClick={() => {
                            setShowChannelDetails(true);
                        }}
                    >
                        <img className="h-8 w-8 rounded-full border-2" src={data?.channel?.thumbnail} alt="" />
                        <div className="text-sm lg:text-md text-slate-500 ml-2">
                            {data?.channel?.name}
                        </div>
                        {/* Channel Preview */}
                        <div
                            className="invisible absolute z-40 top-[105%] left-0 opacity-0 translate-y-20 group-[.popup-open]:visible group-[.popup-open]:opacity-100 group-[.popup-open]:translate-y-0 duration-300"
                        >
                            <div
                                className="flex flex-col h-52 w-64 bg-slate-50 shadow-lg border rounded-lg p-1 cursor-auto"
                            >
                                {
                                    showChannelDetails &&
                                    (<ChannelDetailsPopUp
                                        channel={data?.channel}
                                    />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>)
}

const ChannelDetailsPopUp = ({
    channel,
}) => {

    const toKMB = (num) => {
        if (num < 1000) return num;
        if (num < 1000000) return (num / 1000).toFixed(1) + 'K';
        if (num < 1000000000) return (num / 1000000).toFixed(1) + 'M';
        if (num < 1000000000000) return (num / 1000000000).toFixed(1) + 'B';
        return Math.floor(num / 1000000000000) + 'T';
    }

    const { data, isLoading } = useQuery({
        queryKey: ['channel', channel?.channel],
        queryFn: async ({ queryKey }) => {
            let [, id] = queryKey;
            let response = await getChannelDetails(id);
            return response.data ?? {};
        },
        staleTime: 60 * 60,
    })
    return (<>

        <div
            className="flex flex-grow w-full justify-center items-center"
        >
            {
                isLoading && (
                    <span>
                        loading...
                    </span>
                )
            }
            {
                !isLoading && (
                    <div className="flex flex-col items-center justify-center">
                        <img
                            className="h-20 w-20 rounded-full border-2 border-slate-300"
                            src={data?.thumbnail}
                            alt=""
                        />
                        <div className="text-sm text-slate-500 font-medium mt-1">
                            {data?.name}
                        </div>
                        {/* Statistics */}
                        <div className="flex flex-row items-center justify-center mt-2">
                            <div className="flex flex-col items-center justify-center">
                                <div className="text-sm text-slate-500">
                                    {data?.statistics?.videos.toLocaleString()}
                                </div>
                                <div className="text-xs text-slate-400">
                                    Videos
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center ml-4">
                                <div className="text-sm text-slate-500">
                                    {toKMB(data?.statistics?.views)}
                                    <span
                                        className={`text-xs font-semibold ${data?.statistics?.viewsNew > 0 ? 'text-green-500' : data?.statistics?.viewsNew < 0 ? 'text-red-500' : 'text-slate-500'}`}
                                    >
                                        ({data?.statistics?.viewsNew > 0 ?
                                            "+"
                                            :
                                            null
                                        }
                                        {/* {data?.statistics?.viewsNew.toLocaleString()} */}
                                        {toKMB(data?.statistics?.viewsNew)}
                                        )
                                    </span>
                                </div>
                                <div className="text-xs text-slate-400">
                                    Views
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center ml-4">
                                <div className="text-sm text-slate-500">
                                    {/* {data?.statistics?.subscribers.toLocaleString()} */}
                                    {toKMB(data?.statistics?.subscribers)}
                                    <span
                                        className={`text-xs font-semibold ${data?.statistics?.subNew > 0 ? 'text-green-500' : data?.statistics?.subNew < 0 ? 'text-red-500' : 'text-slate-500'}`}
                                    >
                                        ({data?.statistics?.subNew > 0 ?
                                            "+"
                                            :
                                            null
                                        }
                                        {/* {data?.statistics?.subNew.toLocaleString()} */}
                                        {toKMB(data?.statistics?.subNew)}
                                        )
                                    </span>
                                </div>
                                <div className="text-xs text-slate-400">
                                    Subscribers
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
        <div
            className="flex flex-row gap-2"
        >
            <div
                className="flex flex-grow text-white bg-red-500 hover:bg-red-600 items-center justify-center text-sm py-1 cursor-pointer rounded duration-200"
                title="View on YouTube"
                onClick={() => {
                    window.open(
                        `https://www.youtube.com/channel/${channel?.channel}/`,
                        '_blank'
                    )
                }}
            >
                <Youtube
                    size={18}
                />
                {/* View on YouTube */}
            </div>
            <div
                className="flex flex-grow text-white bg-blue-500 hover:bg-blue-600 items-center justify-center text-sm py-1 cursor-pointer rounded duration-200"
                title="View Channel Analytics"
                onClick={() => {
                    window.open(
                        `channel/${channel?.channel}`,
                        '_blank'
                    )
                }}
            >
                <BarChartBig
                    size={18}
                />
            </div>
        </div>
    </>)
}

function calcText(length) {
    let hours = Math.floor(length / 10000);
    let minutes = Math.floor(length / 100);
    let seconds = length - minutes * 100;
    if (hours > 0) {
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function getRelativeDays(date) {
    let diff = new Date() - new Date(date);
    let days = Math.round(diff / (1000 * 60 * 60 * 24));
    if (days === 0) {
        return 'Today';
    }
    if (days === 1) {
        return 'Yesterday';
    }
    if (days > 5 && days < 7) {
        return 'A week ago';
    }
    if (days > 7 && days < 14) {
        return '2 weeks ago';
    }
    if (days > 14 && days < 21) {
        return '3 weeks ago';
    }
    if (days > 21 && days < 28) {
        return '4 weeks ago';
    }
    if (days > 28 && days < 31) {
        return 'A month ago';
    }
    if (days > 31 && days < 300) {
        return `${Math.round(days / 30)} months ago`;
    }
    if (days > 300) {
        let yrs = Math.round(days / 365);
        if (yrs > 1) {
            return `${yrs} years ago`
        }
        return 'An year ago'
    }
    return `${days} days ago`;
}