import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        loggedIn: localStorage.getItem('token') ? true : false,
        name: '',
        subscription: '',
    },
    reducers: {
        login: (state, action) => {
            console.log(action.payload);
            console.log(state)
            const { token } = action.payload;
            localStorage.setItem('token', token);
            state = { ...state, loggedIn: true };
            return state;
        },
        logout: (state) => {
            localStorage.clear();
            state = { ...state, loggedIn: false };
            return state;
        },
        loadUser: (state, action) => {
            const { name, subscription } = action.payload;
            state = { ...state, name, subscription };
            return state;
        },
    },
});

export const { login, logout, loadUser } = userSlice.actions;

export default userSlice.reducer;
