import { GalleryVerticalEndIcon, Group, Plus, ScanSearch, Tv2 } from "lucide-react";
import { KpiAction, KpiCard } from "../../../Components/KpiCard";
import { useNavigate } from "react-router-dom";

export default function DataKPIs({
    data,
    isLoading,
}) {
    const navigate = useNavigate();
    return (<div
        className="flex flex-wrap flex-row justify-start items-stretch gap-2"
    >
        <KpiCard
            title="Categories"
            value={data?.total_categories ?? <span className="text-slate-400 font-medium text-sm mt-auto mb-auto">
                Comming Soon
            </span>}
            isLoading={isLoading}
            icon={<Group
                size={24}
                strokeWidth={2}
            />}
        // action={<KpiAction
        //     icon={<Plus
        //         size={18}
        //         strokeWidth={2}
        //     />}
        //     bgColor="bg-red-50"
        //     color="text-red-500"
        //     hoverBgColor="bg-red-100"
        //     tooltip="Add New Category"
        // />}
        />
        <KpiCard
            title="Channels"
            value={data?.summary?.total_channels}
            isLoading={isLoading}
            icon={<Tv2
                size={24}
                strokeWidth={2}
                className="text-purple-500"
            />}
            // action={<KpiAction
            //     icon={<Plus
            //         size={18}
            //         strokeWidth={2}
            //     />}
            //     bgColor="bg-purple-50"
            //     color="text-purple-500"
            //     hoverBgColor="bg-purple-100"
            //     tooltip="Add New Channel"
            // />}
            children={<div className="text-sm text-slate-400 font-semibold mt-auto mb-auto">
                Max: {data?.subscription?.max_channels}
            </div>}
            progressBar={<div className="w-full h-1 bg-slate-100 rounded-lg">
                <div className="h-full bg-purple-500 rounded-lg duration-500" style={{ width: `${data ? (data?.summary?.total_channels / data?.subscription?.max_channels * 100) : 100}%` }} />
            </div>}
        />
        <KpiCard
            title="Videos"
            value={data?.summary?.total_videos}
            isLoading={isLoading}
            icon={<GalleryVerticalEndIcon
                size={24}
                strokeWidth={2}
                className="text-blue-500"
            />}
        />
        <KpiCard
            title="Competitor Researches"
            value={data?.summary?.competitor_research}
            isLoading={isLoading}
            icon={<ScanSearch
                size={24}
                strokeWidth={2}
                className="text-green-500"
            />}
            action={<KpiAction
                icon={<Plus
                    size={18}
                    strokeWidth={2}
                />}
                className="relative group p-1 rounded cursor-pointer bg-green-50 text-green-500 hover:bg-green-100 duration-200"
                tooltip="Start New Research"
                onClick={() => {
                    navigate('/research');
                }}
            />}
        />
    </div>)
}