import axios from 'axios';
import { store } from '../state/reducer';
import { BASE_URL } from './consts';
import { logout } from '../state/auth/slice';

const axiosClient = axios.create({
    baseURL: BASE_URL + '/api',
});

axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
});

axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 401) {
                store.dispatch(logout());
            } else if (status === 500) {
                error.response.body = {
                    status: false,
                    message: 'Unknown error occurred. Please try again.',
                };
            }
            return data;
        }
        return Promise.reject(error);
    }
);

export default axiosClient;